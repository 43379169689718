import React, {useEffect, useState} from "react";
import {callApiCrearNotificacionopo, callApiOposBuscarOposicionDetalleUrlAmigable} from "../../core/restUtils";
import {formatDate} from "../../core/utiles";
import Menu from "../../component/menu/menu";
import Footer from "../../component/footer/footer";
import {useParams} from "react-router-dom";
import './oposicion.css';
import {CONSTANTE_ARCHIVO_PDF_BASE64, CONSTANTE_EXTENSION_PDF} from "../../core/constantes";

export default function Oposicion() {

    const [posts, setPosts] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const { opo } = useParams();
    const [values, setValues] = React.useState({
        email: "",
    });

    useEffect(() => {
        setLoading(true);
        getDataOposicionDetallePorUrl(opo);
    }, []);

    function handleChange(evt) {
        /*
          evt.target es el elemento que ejecuto el evento
          name identifica el input y value describe el valor actual
        */
        const { target } = evt;
        const { name, value } = target;

        /*
          Este snippet:
          1. Clona el estado actual
          2. Reemplaza solo el valor del
             input que ejecutó el evento
        */
        const newValues = {
            ...values,
            [name]: value,
        };

        // Sincroniza el estado de nuevo
        setValues(newValues);
    }

    const addAvisoProcesoOpoEvent = (event) => {
        event.preventDefault();
        anadirAvisoOpoPorEmail(values.email, posts.uuidEntidad)
    };

    const getDataOposicionDetallePorUrl = async (urlAmigable) => {
        try {
            const result = await callApiOposBuscarOposicionDetalleUrlAmigable(urlAmigable)
            setPosts(result);
        } catch (error) {
            setError(error);
        } finally {
            setLoading(false);
        }
    };

    const anadirAvisoOpoPorEmail = async (email, uuidProcesoOpo) => {
        try {
           await callApiCrearNotificacionopo(uuidProcesoOpo, '', 'EMAIL', email)
        } catch (error) {
            alert("Error al crear notificación")
        } finally {
            alert("Aviso de notificación creado correctamente")
        }
    };

    const descargarArchivo = (base64Data, nombreArchivo) => {
        // Crear un enlace de descarga
        const link = document.createElement('a');
        link.href = base64Data;
        link.download = nombreArchivo;

        // Programáticamente hacer clic en el enlace
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    const replaceSpacesWithUnderscore = (text) => {
        return text.replace(/\s+/g, '_');
    };

    const renderOposicionDetalle = () => {

        if (loading) return <div className="carga"><p><img key="carga" alt="carga" src="/public/loading.gif" height="100" width="100"/></p></div>;
        if (error) return <div className="carga"><p>Error: {error.message}</p></div>;

        // Formatear a data
        const fechaFormateada = formatDate(posts.fechaBopInicioProceso[2], posts.fechaBopInicioProceso[1], posts.fechaBopInicioProceso[0])

        return (
            <>
                <img key="carga" alt="carga" src={posts.urlImagen} height="200px" width="auto"/>
                <h1>{posts.titulo}</h1>

                <div id="contenedorDatos">
                    <p>{posts.titulo}</p>
                    <h4>Estado oferta: {posts.procesoFinalizado ? 'Pechado' : 'Aberto'}</h4>
                    <h4>Sistema de selección: {posts.sistemaSeleccion}</h4>
                    <h4>Tipo: {posts.tipoContrato}</h4>
                    <h4>Boletín Oficial publicación: {posts.bop}</h4>
                    <h4>Data publicación en Boletín Oficial : {fechaFormateada}</h4>

                </div>

                {posts.procesosOposicionesList.map(oposiAux => (
                    oposiAux.archivo == null ? (
                        <div className="listaEdictos">
                            <a href={oposiAux.urlDetalle} target="_blank"><h5>{oposiAux.titulo}</h5></a>
                            <h5>Fecha
                                BOP: {oposiAux.fechaBop != null ? formatDate(oposiAux.fechaBop[2], oposiAux.fechaBop[1], oposiAux.fechaBop[0]) : null}</h5>
                        </div>
                    )
                    : (
                            oposiAux.urlDetalle == null ? (
                                <div className="listaEdictos">
                                    <a onClick={() => descargarArchivo(CONSTANTE_ARCHIVO_PDF_BASE64 + oposiAux.archivo, replaceSpacesWithUnderscore(oposiAux.titulo) + CONSTANTE_EXTENSION_PDF)}>
                                        <h5>{oposiAux.titulo}</h5>
                                    </a>
                                    <h5>Fecha
                                        BOP: {oposiAux.fechaBop != null ? formatDate(oposiAux.fechaBop[2], oposiAux.fechaBop[1], oposiAux.fechaBop[0]) : null}</h5>
                                </div>
                            ) : (
                                <div className="listaEdictos">
                                    <a onClick={() => descargarArchivo(CONSTANTE_ARCHIVO_PDF_BASE64 + oposiAux.archivo, replaceSpacesWithUnderscore(oposiAux.titulo) + CONSTANTE_EXTENSION_PDF)}>
                                        Enlace - <h5>{oposiAux.titulo}</h5>
                                    </a>
                                    <a href={oposiAux.urlDetalle} target="_blank"><h5>Archivo - {oposiAux.titulo}</h5></a>
                                    <h5>Fecha
                                        BOP: {oposiAux.fechaBop != null ? formatDate(oposiAux.fechaBop[2], oposiAux.fechaBop[1], oposiAux.fechaBop[0]) : null}</h5>
                                </div>
                            )
                        )
                ))}

                <div className="subscription-container">
                    <h2>Subscríbete aos avisos desta OPO!</h2>
                    <p>Recibe notificacions importantes directamente no teu correo electrónico.</p>
                    <input type="email" name="email" onChange={handleChange} placeholder="Introduce o teu correo electrónico" required/>
                    <button onClick={addAvisoProcesoOpoEvent}>Subscribirme</button>
                </div>

            </>
        );
    };

    return (
        <>
            {<Menu/>}
            <div id="oposicionDetalleId" className="oposicionDetalle">
                {renderOposicionDetalle()}
            </div>
            {<Footer/>}
        </>
    );
}